import { isMobile } from "mobile-device-detect";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./_Header.scss";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import {ClientUrl} from "../../utils"

function Header({isAuthenticated}) {
  if (!isMobile) return <DHeader isAuthenticated={isAuthenticated} />;
  return <MHeader isAuthenticated={isAuthenticated} />;
}
function MHeader({isAuthenticated}) {
  const [open, setOpen] = useState(false);
  const hist = useHistory()
  return (
    <div className="MHeader flex row jbetween acenter">
      <Link
        to={"/"}
        onClick={() => {
          setOpen(false);
        }}
      >
        <img src="/icons/autvid.svg" alt={"Logo"} className="MHeader_icon" />
      </Link>

        <img 
          className="MHeader_open"
          onClick={() => {
            setOpen(true);
          }}
         src="/icons/menuBar.svg" alt={"Logo"} />

      {open && (
        <div className="MHeader_panel flex column jbetween">
          <div>
            <div
              className="MHeader_panel_close flex"
              onClick={() => {
                setOpen(false);
              }}
            >
              ✖
            </div>
            <div className="MHeader_panel_link flex  column">
              {[
                {name:"Home", id:""},
                { name: "Features", id: "features" },
                { name: "Templates", id: "templates" },
                { name: "Pricing", id: "pricing" },
              ].map((e) => (
                <Link
                  to={`/${e.id}`}
                  onClick={() => {
                    let demoVideo = document.getElementById('Fvideo-me');
                    if(e.id!=="features" && demoVideo){
                      if(!demoVideo.paused){
                        demoVideo.pause()
                        document._videoInfo = {time: demoVideo.currentTime,muted: demoVideo.muted}
                      }
                      demoVideo?.pause();
                      demoVideo?.removeAttribute('src'); // empty source
                      demoVideo?.load();
                    }
                    window.scrollTo(0, 0);
                    setOpen(false);
                  }}
                >
                  <div className="MHeader_panel_link_one">
                    {e.name} {`>`}
                  </div>
                </Link>
              ))}
            </div>
          </div>
          
            {/* pre-launch-deal */}
          {/* { <div className="Header_auth flex column">
          
            <a  href={`${ClientUrl}/login`}
           className="Header_auth_login flex jcenter acenter">
              Log in
            </a>
 

            <a
        href ={`${ClientUrl}/register`}
        
        className="Header_auth_signup flex jcenter acenter">
          Sign Up
        </a>
          </div>} */}
        </div>
      )}
    </div>
  );
}

function DHeader({isAuthenticated}) {
  const [a, seta] = useState(Math.random());
  const hist = useHistory()
  return (
    <div className="Header flex acenter jbetween LandingPage_bigContent_logo">
      <Link
        to={"/"}
        onClick={() => {
          setTimeout(() => {
            seta(Math.random());
          }, 0);
        }}
      >
        <img src="/icons/autvid.svg" alt={"Logo"} className="Header_icon" />
      </Link>
      <div className="Header_links flex acenter">
        {[
          
          { name: "Features", id: "features" },
          { name: "Templates", id: "templates" },
          { name: "Pricing", id: "pricing" },
        ].map((e) => (
          <Link
            to={`/${e.id}`}
            onClick={() => {
              setTimeout(() => {
                seta(Math.random());
              }, 0);
            }}
          >
            <div
              className={`Header_links_tab ${
                RegExp(`/${e.id}`).exec(document.location.pathname)?.[0]
                  ? "Header_links_active flex acenter jcenter"
                  : ""
              }`}
            >
              {e.name}
            </div>
          </Link>
        ))}
      </div>
      {/* pre-launch-deal */}
    { <div className="Header_auth flex">
    {/* <a  href={`${ClientUrl}/login`} target="_blank"
           className="Header_auth_login flex jcenter acenter">
              Log in
            </a>
        <a
        href ={`${ClientUrl}/register`}
        target="_blank"
        className="Header_auth_signup flex jcenter acenter">
          Sign Up
        </a> */}
      </div>}
    </div>
  );
}

const mapStateToProps = (state) => {
  
  return({
  isAuthenticated: state.auth.isAuthenticated,
})};

export default connect(mapStateToProps)(Header);
