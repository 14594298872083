import React, {useEffect} from "react";
import { D, IPoints } from "./DFeatures";
import speechImg from "./images/speech.webp";
import editorImg from "./images/editor.webp";
import salesImg from "./images/sales.webp";
import thumbImg from "./images/thumb.webp";
import pageImg from "./images/page.webp";
import Button from "../Template/Button";
export default function MFeatures() {

  const handleMute = () =>{
  
    let demoVideo = document.getElementById('Fvideo-me')
    demoVideo.muted = !demoVideo.muted;
    var btn = document.getElementById("mutebtn-fvid")
    if(demoVideo.muted)
    {
  
     btn.innerHTML =  "Play with Sound  <img src='/icons/info/speaker.svg' alt='' />"
    }
    else{
      btn.innerHTML = "Play without Sound"
    }
  }
  
  
  useEffect(() => {
    let demoVideo = document.getElementById('Fvideo-me')
  
    if(demoVideo && window.location.href.includes("feature"))
    {  
    function playVideoOnScroll () {
    
       const threshold = 600 //px above the video to start playing
       let offset = demoVideo.getBoundingClientRect().top

  
       if ((offset < threshold) && (offset>-30) && offset!==0 ) {
        if(demoVideo.paused){
          if(parseFloat(document._videoInfo?.time)){
            if(document._videoInfo?.time<demoVideo.duration){
             demoVideo.currentTime = document._videoInfo?.time;
            }else{
             demoVideo.currentTime = 0;
            }
            demoVideo.muted = document._videoInfo.muted;
          }
          demoVideo.play()
        }
       } else {
         if(!demoVideo.paused){
           demoVideo.pause()
           document._videoInfo = {time: demoVideo.currentTime,muted: demoVideo.muted}
         }
       }
     }
  window.addEventListener('scroll', playVideoOnScroll, false)
  window.addEventListener('resize', playVideoOnScroll, false)}
  if(!window.location.href.includes("feature")){
    demoVideo.pause();
    document._videoInfo = {time: demoVideo.currentTime,muted: demoVideo.muted}
  }
     
   
  })
  

  return (
    <div className="MFeatures">
      <div className="FHeading">{D.heading}</div>
      <div
        className="FNormalText"
        dangerouslySetInnerHTML={{
          __html: `With AutoVid, you can create stunning business videos as simply as typing.  Redefine digital experiences using scalable human connections!`,
        }}
      ></div>
      <div className="flex column jcenter acenter FPoint">
        {D.points.map((e, i) => (
          <div className="FPoint_point flex acenter" key={i}>
            {/* <div className="FPoint_point_key">{i + 1}</div> */}
            <div className="FPoint_point_value">{e}</div>
          </div>
        ))}
      </div>
      <div className="FHeading">{D.points[D.speech.heading]}</div>

      <div className="FColumn flex column C6">
        <div className="FColumn_img">
          <img src={speechImg} alt="speechImage" />
        </div>
        <div className="BigGradient"></div>
        <div className="FColumn_text C1 C4">
          <IPoints {...D.speech} />
        </div>
      </div>

      <div className="FColumn flex  C2">
        <div className="FHeading">{D.points[D.editor.heading]}</div>
        <div className="FColumn_img">
          <img src={editorImg} alt="speechImage" />
        </div>
        <div className="FColumn_text ">
          <IPoints {...D.editor} />
        </div>
      </div>

      <div className="FColumn flex C2 C3 C6">
        <div className="FHeading">{D.points[D.sales.heading]}</div>
        <div className="FColumn_img">
          <img src={salesImg} alt="speechImage" />
        </div>
        <div className="BigGradient C7"></div>
        <div className="FColumn_text C1">
          <IPoints {...D.sales} />
        </div>
      </div>

      <div className="FHeading C2">{D.points[D.hyper.heading]}</div>
      <div className="flex jbetween FPoint FPoint2 acenter">
        {D.hyper.text.map((e, i) => (
          <div className="FPoint_point flex acenter" key={i}>
            <div className="FPoint_point_key flex acenter jcenter">
              <img src={`${D.hyper.dir}/${D.hyper.icon[i]}`} alt="hYe" />
            </div>
            <div className="FPoint_point_value">{e}</div>
          </div>
        ))}
      </div>
      <div className="FVideo">
          <Button id="mutebtn-fvid" value={<>Play with Sound  <img src="/icons/info/speaker.svg" alt="" /></>} onClick={handleMute} className="btn-muted-features"  />
          <video src="/video/VP2.mp4" id="Fvideo-me" muted loop></video>
        </div>

      {/* Thumb */}
      <div className="FColumn flex C2 C3  ">
        <div className="FHeading">{D.thumb.heading}</div>
        <div className="FColumn_img">
          <img src={thumbImg} alt="speechImage" />
        </div>
        <div className="FColumn_text C1">
          <IPoints {...D.thumb} />
        </div>
      </div>

      {/* downward arrow */}
<div className="FColumn flex C6 downarrow">
        
        <img src="/icons/Fdownwardarrow.svg" alt="" />
      </div>

      {/* Page */}
      <div className="FColumn flex C2 C3 C5 C6">
        <div className="FHeading">{D.page.heading}</div>
        <div className="FColumn_img">
          <img src={pageImg} alt="speechImage" />
        </div>
        <div className="BigGradient"></div>
        <div className="FColumn_text ">
          <IPoints {...D.page} />
        </div>
      </div>
    </div>
  );
}
