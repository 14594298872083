import React, { useState } from "react"; //check

import Card from "./Card"; //check
// import TabHeader from "./TabHeader";
import Modal from "reactjs-popup"; //check
import Popup from "./Popup";

import InfiniteWrapper from "./Infinite"; //check
// import store from "../appStore";

import uuid from "uuid-random"; //ehck
import { connect } from "react-redux"; //check
import { FirstMyVideoCard } from "./MyVideo";
import AvatarChoosePopup from "./AvatarChoosePopup";

import Button from "./Button";
import { fetcher, url ,ClientUrl } from "../../utils";
import store from "../../store";
import { DToggle } from "../Pricing/DPricing";
import "./_Template.scss";
import { isMobile } from "mobile-device-detect";
import TabHeader from "./TabHeader";



export default connect((state) => ({
	human: state.media.human,
	nonhuman: state.media.nonhuman,
}))(function Templates({ human, nonhuman }) {
	const [tab, setTab] = useState(0);
	// const [lists, setLists] = useState({ human: [], nonhuman: [] });
	const handleTab = (i) => {
		setTab(i);
		
	};
	// useEffect(() => {
	//   document.title = "AI Generated Human Videos | Template"
	// }, []);

	return (
		<div className={`Templates ${isMobile ? "MTemplates" : "DTemplates"}`}>
			<br />
			  <center> Create from scratch or use the templates below </center>

			{!isMobile && (
				<TabHeader
					arrTab={[{ name: "Avatar Videos" }, { name: "Non Avatar Videos" }]}
					onTab={handleTab}
					nowTab={tab}
				/>
			)}
			{isMobile && (
				<DToggle
					onClick={handleTab}
					arr={["Avatar Videos", "Non Avatar Videos"]}
				/>
			)}
			<center style={{paddingTop: "40px"}}> Coming Soon... </center>
			{/* <ListTab
				tab={tab}
				list={{ 0: human.list, 1: nonhuman.list }}
				type={{ 0: "human", 1: "nonhuman" }}
			/> */}
		</div>
	);
});

export function ListTab({ tab, type = {}, list = {}, frontList = {} }) {
	const id = uuid();
	let _id = `_${id};`;
	if (tab === 0) {
		return (
			<div className="ListTab flex" id={_id}>
				{/* {frontList[tab]} */}
				<InfiniteWrapper type={type[tab]} target={_id} store={store}>
					{[
						...list[tab].map((e, i) => {
							return (
								<ModelCardVideo
									key={e.id}
									name={e.name}
									src={e.hVideo.thumbnail}
									data={e}
									
									id={e.id}
								/>
							);
						}),
					]}
				</InfiniteWrapper>
			</div>
		);
	} else if (tab === 1) {
		return (
			<div className="ListTab flex" id={_id}>
				{/* {frontList[tab]} */}
				<InfiniteWrapper type={type[tab]} target={_id} store={store}>
					{[
						...list[tab].map((e, i) => {
							return (
								<ModelCardVideo
									key={e.id}
									name={e.name}
									src={e.hVideo.thumbnail}
									data={e}
									id={e.id}
								/>
							);
						}),
					]}
				</InfiniteWrapper>
			</div>
		);
	}
}

let Si = {
	0: "hVideo",
	1: "sVideo",
	2: "vVideo",
};

const ModelCardVideo = (e) => {
	const [tab, setTab] = useState(0);
	const handleRatio = (k) => {
		//console.log(k);
		setTab(k);
	};

	return (
		<Modal
		closeOnDocumentClick={true}
		nested={true}
			trigger={
				<button className="ModelCardVideo">
					<Card src={e.src} name={e.name} isPer={e.data.hVideo.isPersonalized}  />
				</button>
			}
			modal
		>
			{(close) => (
				<Popup
				className={`${isMobile ? "MTemplates" : "DTemplates"} popup-template`}
					name={e.name}
					close={close}
					footer={
						<Button
							value="Edit Template"
							//   {/* pre-launch-deal */}
							onClick={()=>{
								window.open(`/pricing/`,"_self");
							}}
							// onClick={() => {
							// 	const _videoP = document.getElementById("_templateVideo");
							// 	if (_videoP) {
							// 	_videoP.pause()
							// 	}
							// 	window.open(`${ClientUrl}/new-template/?template=${e.data.hVideo.id}`, "_blank");
							// }}
						/>
					}
					centerHeader={false}
					haveButton={true}
				>
					<div className="ModelCardVideo_IMG">
						{/* <img key={tab} src={e.data[Si[tab]].thumbnail} alt="dempopopp" /> */}
						<video
							key={tab}
							id="_templateVideo"
							poster={e.data[Si[tab]].thumbnail}
							src={e.data[Si[tab]].mainVideoGenerate.video}
							controls
							controlsList="nodownload"
							autoPlay
						></video>
					</div>
				</Popup>
			)}
		</Modal>
	);
};

// export default function MTemplate() {
//     return <div className='MTemplate'>

//     </div>;
//   }
