import React from 'react'



export default function FAQ() {

    let Q = [
        {
            id : 1,
            ques : "Is there a refund option?",
            ans : "Yes! You will be eligible for a 100% refund within 15 days of purchase, no questions asked. <br/> P.S.: We highly doubt you will need a refund for such a steal deal!"
        },
        {
            id : 2,
            ques : "How do avatar-video credits work?",
            ans : "1 Avatar-Video Credit = 1 minute of Avatar Video"
        },
        {
            id : 3,
            ques : "What are non-avatar videos?",
            ans : "Non-avatar videos refer to those videos that don't use AI-Avatars and voices."
        },
        {
            id : 4,
            ques : "How can I use my videos?",
            ans : "Videos can either be downloaded or shared using our no-code video-sharing pages. <br/> These pages allow you to share your videos via email, LinkedIn, Twitter, and other messaging platforms."
        },
        {
            id : 5,
            ques : "What are personalized videos?",
            ans : "Videos can be personalized with text, images, or avatars speech. (See example here) <br/> Video personalization is possible for both Avatar and Non-Avatar videos."
        },
        {
            id : 6,
            ques : "How do video personalization credits work?",
            ans : "Each personalized video generated consumes one credit."
        },

        {
            id : 7,
            ques : "What is a personalized thumbnail and sales page?",
            ans : "Thumbnail: It is a personalized image sent via messaging on various platforms such as email, LinkedIn, Twitter, etc., and upon clicking, it takes you to the video-sharing page.<br/> Thumbnail can be personalized with textual fields (such as name, company, etc.) or images (such as website screenshot, display picture, etc.).<br/><br/>Sales Page: It is a video-sharing page on which a person lands after clicking the thumbnail. Sales page can be personalized with textual fields (such as name, company, etc.). You can also include various CTA buttons and marketing collateral on the sales page."
        },

        {
            id : 8,
            ques : "How do thumbnail and sales-page personalization credits work?",
            ans : "Each impression consumes one personalization credit."
        },

        {
            id : 9,
            ques : "What are personalized images?",
            ans : "Personalized images can help you get 4x response rates. You can use them in your outreach over email, LinkedIn, Twitter, or other platforms."
        },

        {
            id : 10,
            ques : "What platforms and tools do you integrate?",
            ans : "We currently integrate with all the leading email providers. We are also developing native integration with automation tools like TexAU/ Phantom Buster which will be available soon. If you cannot find your required integration, contact us and we'll be happy to assist."
        },
        {
            id : 11,
            ques : "How many total personalized credits will I get in this deal?",
            ans : "Each month you will get: 300 Video Personalization + 30,000 Thumbnail Personalization + 30,000 Salespage Personalization + 30,000 Image Personalization"
        },
        ]
    

        const Expand = (id)=>{
            
            var coll = document.getElementById(`faq-ques-${id}`);

// console.log(coll);

var content = coll.nextElementSibling;
console.log(content);
if (content.style.maxHeight){
  content.style.maxHeight = null;
  coll.classList?.remove("active");
} else {
    coll.classList.add("active");
  content.style.maxHeight = content.scrollHeight + "px";
}
            
        }

  return (
<>

<div class="DPricing_heading _FAQ">FAQs</div>

<div className="jcenter acenter">
{Q.map((d,key)=>(
    
    
    <div className="faq-row" key={key} >
    <div   id={`faq-ques-${d.id}`} type="button" className="collapsible " onClick={()=>Expand(d.id)}>{d.ques} <img src="/images/icons/downArrow.svg" alt="" /> </div>
<div className="content">
  <p key={key} dangerouslySetInnerHTML={{ __html:d.ans}}></p>
</div>
    </div>

))}

</div>


</>

  )
}
