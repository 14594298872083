/* eslint-disable */

import React, { useState, useEffect } from "react";
import { login } from "../../actions/auth";
import { connect } from "react-redux";
import { Link, Redirect, useParams, useRouteMatch } from "react-router-dom";
import axios from "axios";
// import { url } from "../../config/config";

import { ReactComponent as VerifyEmailIcon } from "../../extras/misc/mailverify.svg";
import { ReactComponent as Autogenerate } from "../../extras/aiLogo.svg";
import { ReactComponent as CrossError } from "../../extras/misc/loginerrorcross.svg";
import LoadingComp from "../common/loading";
import { ReactComponent as PinkTick } from "../../extras/analytics/tickAnalyticSm.svg";
import { ReactComponent as WhiteTick } from "../../extras/misc/tickWhite.svg";
import { url } from "../../utils";
function Login(props) {
  let { id } = useParams();
  const [Details, setDetails] = useState({
    email:
      props.location && props.location.email
        ? props.location.email
        : "HelpFrom@backend.com",
    code: id,
    password: "",
    password2: "",
  });
  const [InsideErrors, setInsideErrors] = useState({
    isError: false,
    error: [],
  });
  const [Step, setStep] = useState(-1);
  const [Errors, setErrors] = useState({ isError: false });
  const [Redirectt, setRedirectt] = useState(false);
  console.log(props);

  useEffect(async () => {
    const res = await axios.get(
      `${url}/api/accounts/password/reset/verify/?code=${id}`
    );
    console.log(res);
    if (res.data.isError) {
      setStep(0);
      setErrors({
        isError: true,
        message: res.data.message,
      });
    } else {
      setStep(1);
      setDetails({ email: res.data.email });
    }
  }, []);

  console.log(Step);
  console.log();

  useEffect(() => {
    const messages = {
      email: {
        0: "Email is Blocked.",
        1: "Email is not varified.",
        2: "Email is not linked with any organisation.",
      },
      password: {
        0: "Invalid password.",
      },
    };

    if (props.state.auth.payload && props.state.auth.payload.isError) {
      var obj;

      Object.keys(props.state.auth.payload.message).map((d) => {
        obj = {
          isError: true,
          ...obj,
          message: messages[d][props.state.auth.payload.message[d].status],
        };
      });

      setErrors(obj);
    } else {
      setErrors({
        isError: false,
      });
      if (
        props.state.auth.payload &&
        props.state.auth.payload.isError === false &&
        props.isAuthenticated
      ) {
        window.open("/");
      }
    }

    console.log(props.state);
  }, [props.state]);

  console.log(Errors);
  console.log(InsideErrors);

  const handlerSubmit = (e) => {
    var password = /[!@#$%^&*(),.?":{}|<>-_;:|]/g;

    if (Details.password.length >= 8 && Details.password.match(password)) {
      if (
        document.getElementById("password-input").value ===
        document.getElementById("password2-input").value
      ) {
        handleReset();
      } else {
        document.getElementById("password2-input").classList.add("errorInput");
      }
    } else {
      var arr = [];
      var error = false;

      if (Details.password.length <= 8) {
        document.getElementById("password-input").classList.add("errorInput");
        error = true;
        arr.push({
          status: false,
          message: "8 or more characters",
        });
      } else {
        arr.push({
          status: true,
          message: "8 or more characters",
        });
      }

      if (Details.password.match(password)) {
        document.getElementById("password-input").classList.add("errorInput");
        error = true;
        arr.push({
          status: true,
          message: "At least 1 symbol",
        });
      } else {
        arr.push({
          status: false,
          message: "At least 1 symbol",
        });
      }
    }

    if (arr && arr.length > 0 && error) {
      setInsideErrors({
        isError: true,
        error: arr,
      });
    } else {
      setInsideErrors({
        isError: false,
        error: [],
      });
    }
  };

  const handleReset = async (e) => {
    const res = await axios.post(
      `${url}/api/accounts/password/reset/verified/`,
      { code: id, password: Details.password }
    );
    const messages = {
      code: {
        0: "Code not valid",
        1: "Code is expired.",
      },
    };
    console.log(res);
    if (res.data.isError) {
      var obj;

      Object.keys(res.data.message).map((d) => {
        obj = {
          isError: true,
          ...obj,
          message: messages[d][res.data.message[d].status],
        };
      });

      setErrors(obj);
    } else {
      setRedirectt(true);
      setErrors({
        isError: false,
      });
    }
  };
  const handleChange = (e) => {
    e.target.classList.remove("errorInput");
    setErrors({ isError: false });
    setDetails({ ...Details, [e.target.name]: e.target.value });
  };
  console.log(Details);
  const al = () => {
    if (Step === 0) {
      return (
        <div
          key={1}
          className={`login-wrapper ${Step === 1 ? "MoveLeft" : ""}`}
          style={{ maxWidth: "513px" }}
        >
          <Autogenerate style={{ width: "240px" }} />

          <h1 className="login-header">Verify email Failed.</h1>

          <div className="login-inner-wrapper">
            <div className="login-gp-1">
              <label className="login-main-label">
                Verification of email failed.{" "}
              </label>
              {/* <label className="login-verification-label">{Details.email}</label> */}

              {/* <GoogleAuth /> */}
              {/* <button className="google-login"><Google style={{margin:"16px"}} /> Continue with Google</button> */}
            </div>

            <div className="login-form">
              <VerifyEmailIcon />
            </div>

            <div className="login-footer">
              <Link
                to={{
                  pathname: "/login",
                  // email:Details.email
                }}
                className="login-main-button link-custom"
                style={{ margin: "50px 0 0 0", width: "200px" }}
                onClick={(e) => {
                  handlerSubmit(e);
                }}
              >
                Login
              </Link>

              {/* <Link to="/signup" className="link-custom forget-login-main" >Forgot Password?</Link> */}
            </div>
          </div>
        </div>
      );
    }

    if (Step === -1) {
      return <LoadingComp style={{ width: "100vw", height: "100vh" }} />;
    }

    if (Step === 1) {
      return (
        <div className="login-wrapper">
          <Autogenerate style={{ width: "240px" }} />

          <h1 className="login-header">Change Password.</h1>

          <div className="login-inner-wrapper">
            <div className="login-gp-1">
              <label className="login-main-label">
                Change password for account linked to{" "}
              </label>
              <label
                className="login-verification-label"
                style={{ margin: "0 0 40px 0" }}
              >
                {Details.email}
              </label>

              {/* <GoogleAuth /> */}
              {/* <button className="google-login"><Google style={{margin:"16px"}} /> Continue with Google</button> */}
            </div>

            <div className="login-form">
              {Errors.isError ? (
                <div className="error-login-info">
                  <label htmlFor="" className="error-login-info-heading">
                    Error !
                  </label>
                  <label htmlFor="" className="error-login-info-label">
                    {Errors.message}
                  </label>
                  <CrossError
                    className="pointer"
                    onClick={() => {
                      setErrors({
                        ...Errors,
                        isError: false,
                      });
                    }}
                    style={{ position: "absolute", right: "16px", top: "35%" }}
                  />
                </div>
              ) : null}

              <span style={{ width: "100%", position: "relative" }}>
                <input
                  id="password-input"
                  type="password"
                  className="login-item"
                  placeholder="Enter New Password"
                  name="password"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {InsideErrors.isError ? (
                  <div className="error-info-tooltip-tool">
                    <label htmlFor="" className="error-info-tooltip-head">
                      Password must have:
                    </label>
                    {InsideErrors.error.map((d) => (
                      <label htmlFor="" className="error-info-item">
                        {" "}
                        {d.status ? <PinkTick /> : <WhiteTick />} &nbsp;{" "}
                        {d.message}
                      </label>
                    ))}
                  </div>
                ) : null}
              </span>
              <input
                id="password2-input"
                type="password"
                className="login-item"
                placeholder="Re-enter New Password"
                name="password2"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>

            <div className="login-footer">
              <button
                className="login-main-button"
                onClick={(e) => {
                  handlerSubmit(e);
                }}
              >
                Save Password
              </button>

              <label className="login-main-label">
                Go back to{" "}
                <Link to="/login" className="link-custom">
                  Log in
                </Link>
              </label>
            </div>
          </div>
        </div>
      );
    }
  };

  // console.log(props);

  if (Redirectt) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          email: Details.email,
          passwordChange: true,
        }}
      />
    );
  } else return <div className="login-container">{al()}</div>;
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  state: state,
});
export default connect(mapStateToProps, { login })(Login);
