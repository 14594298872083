import axios from "axios";

export const token = localStorage.getItem("token");
// export const url =
export const url = "https://api.autogenerate.ai";
export const ClientUrl = window.location.href.includes("localhost")?"http://localhost:3000":"https://app.autovid.ai"
export const headerA = {
	headers: {
		// Authorization: `Token ${token}`,
	},
};


export const fetcher = ({
	header = {},
	method,
	url = "",
	data = {},
	res = () => {},
	err = () => {},
}) => {
	if (!method) {
		method = "GET";
	}
	var header 
let newHead = header || headerA;
	axios({
		
		url,
		method,
		data,
		// baseURL: `${url}/api`,
	})
		.then(res)
		.catch(err);
};
