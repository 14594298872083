import React, { useEffect } from "react";
import "./_Features.scss";
import speechImg from "./images/speech.webp";
import editorImg from "./images/editor.webp";
import salesImg from "./images/sales.webp";
import thumbImg from "./images/thumb.webp";
import pageImg from "./images/page.webp";
import Button from "../Template/Button";
export const D = {
  heading: `Autovid AI Studio`,
  description: `With AutoVid, you can create stunning business videos as simply as typing. <br /> Redefine digital experiences using scalable human connections!`,
  points: [
    "Realistic avatars & speech",
    "Powerful video editor",
    "Customized sales pages",
    "Hyper-personalization",
  ],

  speech: {
    heading: 0,
    text: ["Text to video", "50+ Languages", "5+ Avatars", "Custom Audio"],
    icon: ["play.svg", "globe.svg", "user.svg", "audio.svg"],
    dir: "/images/icons",
  },
  editor: {
    heading: 1,
    text: [
      "Create Avatar and Non-Avatar Videos",
      "3 million+ stock videos, photos & music tracks",
      "200+ transitions, animations & filters",
      "Full HD (1920x1080) resolution",
      "100+ templates",
      "Brand color kit",
      "Timeline view",
    ],
    icon: [
      "user.svg",
      "photos.svg",
      "filter.svg",
      "hd.svg",
      "templates.svg",
      "color.svg",
      "timeline.svg",
    ],
    dir: "/images/icons",
  },
  sales: {
    heading: 2,
    text: [
      "No-code video sharing pages",
      "Personalized text and video thumbnails",
      "Call to action buttons",
      "Carousel for marketing collateral",
    ],
    icon: ["nocode.svg", "per.svg", "pointer.svg", "photos.svg"],
    dir: "/images/icons",
  },
  hyper: {
    heading: 3,
    text: ["Personalised Speech", "Personalised Text", "Personalised Image"],
    icon: ["message.svg", "text.svg", "perimage.svg"],
    dir: "/images/icons",
  },
  thumb: {
    heading: "Personalised Thumbnail",
    text: [
      "Supports LinkedIn, email, and messaging platforms",
      "Personalized text, images, and website screenshots",
      "50+ email client integrations",
    ],
    icon: ["link.svg", "per.svg", "at.svg"],
    dir: "/images/icons",
  },
  page: {
    heading: "Personalised Sharing Page",
    text: [
      "Brand customization and presets",
      "Personalized text and video thumbnails",
      "Supports CTA buttons and sales collateral",
    ],
    icon: ["color.svg", "per.svg", "pointer.svg"],
    dir: "/images/icons",
  },
};





export default function DFeatures() {

const handleMute = () =>{
  
  let demoVideo = document.getElementById('Fvideo-me')
  demoVideo.muted = !demoVideo.muted;
  var btn = document.getElementById("mutebtn-fvid")
  if(demoVideo.muted)
  {

   btn.innerHTML =  "Play with Sound  <img src='/icons/info/speaker.svg' alt='' />"
  }
  else{
    btn.innerHTML = "Play without Sound"
  }
}



useEffect(() => {
  let demoVideo = document.getElementById('Fvideo-me')

  if(demoVideo && window.location.href.includes("feature"))
  {  
  function playVideoOnScroll () {
  
     const threshold = 600 //px above the video to start playing
     let offset = demoVideo.getBoundingClientRect().top


     if ((offset < threshold) && (offset>-20) && offset!==0 ) {
       if(demoVideo.paused){
         if(parseFloat(document._videoInfo?.time)){
           if(document._videoInfo?.time<demoVideo.duration){
            demoVideo.currentTime = document._videoInfo?.time;
           }else{
            demoVideo.currentTime = 0;
           }
           demoVideo.muted = document._videoInfo.muted;
         }
         demoVideo.play()
       }
     } else {
      if(!demoVideo.paused){
        demoVideo.pause()
        document._videoInfo = {time: demoVideo.currentTime,muted: demoVideo.muted}
      }
     }
   }
    window.addEventListener('scroll', playVideoOnScroll, false)
    window.addEventListener('resize', playVideoOnScroll, false)}
    if(!window.location.href.includes("feature")){
      if(!demoVideo.paused){
        demoVideo.pause()
        document._videoInfo = {time: demoVideo.currentTime,muted: demoVideo.muted}
      }
    }
   
 
})



  return (
    <div className="DFeatures_wrapper flex column acenter">
      <div className="DFeatures flex column acenter">
        <div className="FHeading">{D.heading}</div>
        <div
          className="FNormalText"
          dangerouslySetInnerHTML={{ __html: D.description }}
        ></div>
        <div className="flex jbetween FPoint">
          {D.points.map((e, i) => (
            <div className="FPoint_point flex acenter" key={i}>
              <div className="FPoint_point_key">{i + 1}</div>
              <div className="FPoint_point_value">{e}</div>
            </div>
          ))}
        </div>

        {/* Speech */}
        <div className="FColumn flex C6">
          <div className="FColumn_img">
            <img src={speechImg} alt="speechImage" />
          </div>
          <div className="BigGradient"></div>
          <div className="FColumn_text C1 C4">
            <div className="FHeading">{D.points[D.speech.heading]}</div>
            <IPoints {...D.speech} />
          </div>
        </div>

        {/* Editor */}
        <div className="FColumn flex C2">
          <div className="FColumn_text ">
            <div className="FHeading">{D.points[D.editor.heading]}</div>
            <IPoints {...D.editor} />
          </div>
          <div className="FColumn_img">
            <img src={editorImg} alt="speechImage" />
          </div>
        </div>

        {/* Sales Page */}
        <div className="FColumn flex C2 C3 C6">
          <div className="FColumn_img">
            <img src={salesImg} alt="speechImage" />
          </div>
          <div className="BigGradient C7"></div>
          <div className="FColumn_text C1">
            <div className="FHeading">{D.points[D.sales.heading]}</div>
            <IPoints {...D.sales} />
          </div>
        </div>

        {/* Hyper */}
        <div className="FHeading C2">{D.points[D.hyper.heading]}</div>
        <div className="flex jbetween FPoint FPoint2">
          {D.hyper.text.map((e, i) => (
            <div className="FPoint_point flex acenter" key={i}>
              <div className="FPoint_point_key flex acenter jcenter">
                <img src={`${D.hyper.dir}/${D.hyper.icon[i]}`} alt="hYe" />
              </div>
              <div className="FPoint_point_value">{e}</div>
            </div>
          ))}
        </div>
        <div className="FVideo">
          <Button id="mutebtn-fvid" value={<>Play with Sound  <img src="/icons/info/speaker.svg" alt="" /></>} onClick={handleMute} className="btn-muted-features"  />
          <video src="/video/VP2.mp4" id="Fvideo-me" muted loop></video>
        </div>

        {/* Thumb */}
        <div className="FColumn flex C2 C3  ">
          <div className="FColumn_img">
            <img src={thumbImg} alt="speechImage" />
          </div>
          <div className="FColumn_text C1">
            <div className="FHeading">{D.thumb.heading}</div>
            <IPoints {...D.thumb} />
          </div>
        </div>

{/* downward arrow */}
<div className="FColumn flex C6 downarrow ">
        
            <img src="/icons/Fdownwardarrow.svg" alt="" />
          </div>
        


        {/* Page */}
        <div className="FColumn flex C2 C3 C5 C6">
          <div className="FColumn_text ">
            <div className="FHeading">{D.page.heading}</div>
            <IPoints {...D.page} />
          </div>
          <div className="BigGradient"></div>
          <div className="FColumn_img">
            <img src={pageImg} alt="speechImage" />
          </div>
        </div>
      </div>
    </div>
  );
}

export const IPoints = ({ text, icon, dir }) => {
  return (
    <div className="IPoints">
      {text.map((e, i) => {
        return (
          <div className="IPoints_point flex">
            <div className="IPoints_point_icon flex acenter jcenter">
              <img src={`${dir}/${icon[i]}`} alt={icon[i]} />
            </div>
            <div className="IPoints_point_text">{e}</div>
          </div>
        );
      })}
    </div>
  );
};

const BigGradient = () => {
  return <div className="BigGradient"></div>;
};
