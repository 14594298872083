/* eslint-disable */
// import $ from "jquery";
import React, { useState, useEffect } from "react";
import { login as LoginNow } from "../../actions/auth";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { ReactComponent as Autogenerate } from "../../extras/aiLogo.svg";
// import { ReactComponent as Google } from "../../extras/misc/logo-google.svg";
import GoogleAuth from "./social-auth/GoogleHooks";

import { ReactComponent as CrossError } from "../../extras/misc/loginerrorcross.svg";
function Login(props) {
	const [Details, setDetails] = useState({
		email: props.location && props.location.email ? props.location.email : "",
		password: "",
	});

	const [Errors, setErrors] = useState({ isError: false });
	const [PasswordChange, setPasswordChange] = useState({ isChanged: false });
	const [EmailVerified, setEmailVerified] = useState({ isChanged: false });



	useEffect(() => {
		const messages = {
			email: {
				0: "Email is Blocked.",
				1: "Email is not varified.",
				2: "Email is not linked with any organisation.",
			},
			password: {
				0: "Invalid password.",
			},
		};

		if (props.state.auth.payload && props.state.auth.payload.isError) {
			var obj;

			Object.keys(props.state.auth.payload.message).map((d) => {
				obj = {
					isError: true,
					...obj,
					message: messages[d][props.state.auth.payload.message[d].status],
				};
			});

			setErrors(obj);
		} else {
			setErrors({
				isError: false,
			});
			if (
				props.state.auth.payload &&
				props.state.auth.payload.isError === false &&
				props.isAuthenticated
			) {
				// window.open("/")
			}
		}


	}, [props.state]);



	useEffect(() => {
		if (props.location.passwordChange) {
			setPasswordChange({
				isChanged: true,
				message: "Login to continue.",
			});
		}
		if (props.location.emailVerified) {
			setEmailVerified({
				isChanged: true,
				message: "Login to continue.",
			});
		}
	}, []);

	const handlerSubmit = (e) => {
		console.log(e);
		var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		// if (Details.email.match(mailformat) && Details.password.length > 3) {
			props.LoginNow(Details.email, Details.password);
		// 	console.log(Details.email, Details.password);
		// } else {
		// 	if (!Details.email.match(mailformat)) {
		// 		document.getElementById("email-input").classList.add("errorInput");
		// 	}

		// 	if (Details.password.length < 3) {
		// 		document.getElementById("password-input").classList.add("errorInput");
		// 	}
		// }
	};

	const handleChange = (e) => {
		e.target.classList.remove("errorInput");
		setErrors({ isError: false });
		setDetails({ ...Details, [e.target.name]: e.target.value });
	};

	// const al = ()=>{
	//   if(Step===0){
	//   return (  <form id="slide-0" key={1} className={`${Step===0?"MoveLeft":""}`} onSubmit={(e)=>{handlerSubmit(e)}}>
	//   form 1
	//   <div className="mb-3">
	//    <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
	//    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="email" onChange={(e)=>{handleChange(e)}}  />
	//    <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
	//   </div>
	//   <div className="mb-3">
	//    <label htmlFor="exampleInputPassword1" className="form-label" >Password</label>
	//    <input type="password" className="form-control" id="exampleInputPassword1"  name="password" onChange={(e)=>{handleChange(e)}} />
	//   </div>

	//   <button type="submit" className="btn btn-primary">Submit</button>
	//   </form>)

	//   }
	//   if(Step===1){
	//     return(<form id="slide-1" key={2} className={`${Step===1?"MoveLeft":""}`}  onSubmit={(e)=>{handlerSubmit(e)}}>
	//     Form 2
	//     <div className="mb-3">
	//       <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
	//       <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="email" onChange={(e)=>{handleChange(e)}}  />
	//       <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
	//     </div>
	//     <div className="mb-3">
	//       <label htmlFor="exampleInputPassword1" className="form-label" >Password</label>
	//       <input type="password" className="form-control" id="exampleInputPassword1"  name="password" onChange={(e)=>{handleChange(e)}} />
	//     </div>

	//     <button type="submit" className="btn btn-primary" >Submit</button>
	//   </form>)
	//   }

	// }

	if (props.isAuthenticated) {
		return <Redirect to="/" />;
	} else
		return (
			<div className="login-container">
				<div className="login-wrapper">
					<div className="login-inner-wrapper">
						<div className="login-gp-1">
							<GoogleAuth />
							{/* <button className="google-login"><Google style={{margin:"16px"}} /> Continue with Google</button> */}
						</div>

						<h6 className="word-btw-line login">
							<span>OR</span>
						</h6>

						<div className="login-form">
							{Errors.isError ? (
								<div className="error-login-info">
									<label htmlFor="" className="error-login-info-heading">
										Error !
									</label>
									<label htmlFor="" className="error-login-info-label">
										{Errors.message}
									</label>
									<CrossError
										className="pointer"
										onClick={() => {
											setErrors({
												...Errors,
												isError: false,
											});
										}}
										style={{ position: "absolute", right: "16px", top: "35%" }}
									/>
								</div>
							) : null}
							{PasswordChange.isChanged ? (
								<div className="error-login-info">
									<label htmlFor="" className="error-login-info-heading">
										Password Changed
									</label>
									<label htmlFor="" className="error-login-info-label">
										{PasswordChange.message}
									</label>
									<CrossError
										className="pointer"
										onClick={() => {
											setPasswordChange({
												isChanged: false,
											});
										}}
										style={{ position: "absolute", right: "16px", top: "35%" }}
									/>
								</div>
							) : null}
							{EmailVerified.isChanged ? (
								<div className="error-login-info">
									<label htmlFor="" className="error-login-info-heading">
										Email verified!
									</label>
									<label htmlFor="" className="error-login-info-label">
										Login to continue.
									</label>
									<CrossError
										className="pointer"
										onClick={() => {
											setEmailVerified({
												isChanged: false,
											});
										}}
										style={{ position: "absolute", right: "16px", top: "35%" }}
									/>
								</div>
							) : null}
							<input
								id="email-input"
								type="email"
								className="login-item"
								placeholder="Email"
								name="email"
								defaultValue={
									props.location && props.location.email
										? props.location.email
										: ""
								}
								onChange={(e) => {
									handleChange(e);
								}}
							/>
							<input
								id="password-input"
								type="password"
								className="login-item"
								placeholder="Password"
								name="password"
								onChange={(e) => {
									handleChange(e);
								}}
							/>
						</div>

						<div className="login-footer">
							<button
								className="login-main-button"
								onClick={(e) => {
									handlerSubmit(e);
								}}
							>
								Login
							</button>

							<label className="login-main-label">
								Don’t have an account?{" "}
								<Link to="/register" className="link-custom">
									Create Account
								</Link>
							</label>
							<Link
								to="/forgotpassword"
								className="link-custom forget-login-main"
							>
								Forgot Password?
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
}
const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	state: state,
});
export default connect(mapStateToProps,{LoginNow})(Login);
