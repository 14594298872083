import { url } from "../utils";

const initState = {
    human:{
        next:`${url}/api/newvideo/video/template/?human=0`,
        list:[]
    },
    nonhuman:{
        next:`${url}/api/newvideo/video/template/`,
        list:[]
    }
}

document.lock = {}
export const media=(state=initState, action)=>{
  const { data } = action;

    switch(action.type){
        case "MEDIA_WITH_NEXT":
            // state.media[data.type].next = data.next;
            // state.media[data.type].list.push(...data.list);
            return {
                ...state,
                [data.type]:{
                    next:data.next,
                    list:[...state[data.type].list, ...data.list]
                }
            }
      

      
        default:
            return state
         
    }
}