import { useEffect, useMemo, useState } from "react";
import "./_LandingPage.scss";

import { isMobile } from "mobile-device-detect";
import { validate } from "react-email-validator";
import { loadUser } from "../../actions/auth";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function LandingPage({loadUser}) {
  const [loaded, setLoaded] = useState(true);

  // const vidRef = useRef();



  useEffect(() => {


    setLoaded((l) => !l);

    document.addEventListener(
      "DOMContentLoaded",
      function () {
        // your code here
        let vid = document.querySelector(".vid");
        let bigVid = document.querySelector(".bigVid");
        vid.play();
        // bigVid.preload = true;
        bigVid.load();
        vid.playbackRate = 0.7;
      },
      false
    );
  }, []);

  useEffect(() => {
    loadUser()
  }, [])
  
  if (!isMobile)
    return (
      <>
        {loaded && <div className="Loading"></div>}
        <Desktop />
      </>
    );
  else return <Mobile></Mobile>;
}

const Input = ({}) => {
  const [send, setSend] = useState(false);
  const [value, setValue] = useState("");
  const [err, setErr] = useState(false);

  const [status, setStatus] = useState("first");
  const handleSend = (e) => {
    e.preventDefault();
    if (validate(value) && status !== "sended") {
      setStatus("sending");
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: "VailidEmailOnClick" });
      fetch("https://api.autogenerate.ai/api/accounts/client/?type=autovid_getearlyaccess", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({ email: value }),
      })
        .then((k) => {
          sessionStorage.setItem("_email",value)
          document.location.href = '/pricing';
          return k.text()
        })
        .then((k) => {
          setStatus("sended");
          // setValue("");
        });
    } else if (status === "sended") {
      return;
    } else {
      setStatus("err");

      setTimeout(() => {
        setStatus("first");
      }, 2000);
    }
  };
  return (
    <>
      <form className="LandingPage_content_input" onSubmit={handleSend}>
        <input
          placeholder="johndoe@mail.com"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          type="text"
        />
        <button
          type="submit"
          className={`sendButton ${
            status === "sended" || status === "sending" || status === "err"
              ? "SENDED"
              : ""
          }`}
        >

          {status === "first" && "Get Early Access"}
          {status === "err" && "Invalid Email"}
          {status === "sending" && (
            <div className="LOADING_IMG">
              {/* Sending  */}

              <img className="loading" src="/icons/Loading.svg" alt="loading" />
            </div>
          )}
          {status === "sended" && (
            <>
              Email Submitted{" "}
              <img src="/icons/check.svg" alt="Get Early Access" />
            </>
          )}
        </button>
      </form>

    
      {/* <div className="LandingPage_content_input">
          <Link to={"/pricing"} className="sendButton" >Get Early Access</Link>
      </div>
       */}

      {status === "sended" && (
        <label className="SENDED_LABEL">
          Thanks for your response! We’ll get back to you soon.
        </label>
      )}
      {status === "first" && (
        <label className="SENDED_LABEL DIFFCOL">
          Use business email for faster access
        </label>
      )}
      {status === "err" && (
        <label className="SENDED_LABEL">
          Please enter a valid email address.
        </label>
      )}
    </>
  );
};
const ele = {
  logo: () => (
    <img
      src="/icons/autvid.svg"
      alt={"Logo"}
      className="LandingPage_bigContent_logo"
    />
  ),
  heading: (isBig) => (
    <div className="LandingPage_content_heading">
      AI Generated
      {isBig && (
        <>
          <img src={"./icons/vidArrow.svg"} alt="Pointing to Here" />
        </>
      )}{" "}
      <br />
      Human Videos
    </div>
  ),
  subheading: () => (
    <div className="LandingPage_content_subheading">
      Convert text to studio quality videos <br /> within minutes
    </div>
  ),
  input: () => <Input />,
  info: (info) => (
    <div className="LandingPage_bigContent_info">
      {info.map((e) => (
        <div className="LandingPage_bigContent_info_col">
          {e.map((k) => {
            return (
              <div className="LandingPage_bigContent_info_col_row">
                <img src={k.src} alt={k.label} />
                <label>{k.label}</label>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  ),
  video: ({ onClickArea, src = "/video/autovid-main-video.mp4", onEnd = () => {} }) => (
    <div className="LandingPage_gif">
      <div className="videoWrapper">
        <video className="vid" loop autoplay muted>
          <source src="/video/smallg.mp4" type="video/mp4" />
        </video>
        <video
          onEnded={onEnd}
          className="bigVid"
          preload="auto"
          controlsList="nodownload"
          controls
        >
          <source src={src} type="video/mp4" />
        </video>
        <img
          alt={"Play Button"}
          src="/icons/playButton.svg"
          className="playButton"
        />
        <div className="ClickableArea" onClick={onClickArea}></div>
      </div>
    </div>
  ),
};
const Desktop = () => {
  const [played, setPlay] = useState(true);

  const info = useMemo(() => {
    return [
      [
        { src: "/icons/info/info1.svg", label: "Explainer Videos" },
        { src: "/icons/info/info5.svg", label: "E-Learning" },
      ],
      [
        { src: "/icons/info/info2.svg", label: "Corporate Communication" },
        { src: "/icons/info/info6.svg", label: "Personalized Sales Outreach" },
      ],
      [
        { src: "/icons/info/info3.svg", label: "Product Demos" },
        { src: "/icons/info/info7.svg", label: "Digital marketing" },
      ],
      [
        { src: "/icons/info/info4.svg", label: "How-tos & Tutorials" },
        { src: "/icons/info/info8.svg", label: "Training Videos (L&D)" },
      ],
    ];
  }, []);
  useEffect(() => {
    // console.log(document.querySelector(".vid").play());
  }, []);
  const handlePlay = (e) => {
    // const vid = e.target;
    let play = document.querySelector(".controls_play");
    let vid = document.querySelector(".bigVid");
    if (played) {
      vid.pause();
    } else {
      vid.play();
    }
    play.className = "controls_play puff-out-center";
    setTimeout(() => {
      if (!played) {
        play.src = "/icons/pause.svg";
      } else {
        play.src = "/icons/play.svg";
      }
      setPlay(!played);
      play.className = "controls_play";
    }, 750);
    // alert("clcik");
  };

  const handleClose = (e) => {
    let _ = e?.stopPropagation();
    let vid = document.querySelector(".vid");
    let videoWrapper = document.querySelector(".videoWrapper");
    let LandingPage_content = document.querySelector(".LandingPage_content");
    let bigVid = document.querySelector(".bigVid");
    let playButton = document.querySelector(".playButton");
    let controls = document.querySelector(".controls");
    let LandingPage_bigContent_info = document.querySelector(
      ".LandingPage_bigContent_info"
    );
    let LandingPage_bigContent_logo = document.querySelector(
      ".LandingPage_bigContent_logo"
    );

    controls.style.display = "none";
    bigVid.style.display = "none";
    vid.style.display = "block";
    LandingPage_content.style.opacity = 1;
    LandingPage_content.style.display = "flex";
    playButton.style.opacity = 1;
    LandingPage_bigContent_logo.style.opacity = 1;
    LandingPage_content.className = "LandingPage_content";
    videoWrapper.className = "videoWrapper";
    playButton.style.display = "block";

    LandingPage_bigContent_info.style.opacity = 1;

    bigVid.pause();
    bigVid.currentTime = 0;
  };

  return (
    <div className="Desktop">
      <div className=" LandingPage">
        <div className="LandingPage_bigContent">
          {/* {ele.logo()} */}
          <div className="LandingPage_content">
            {ele.heading(true)}
            {ele.subheading()}
            {ele.input()}
          </div>
          {ele.info(info)}
        </div>
        {ele.video({
          onClickArea: () => document.handleArea(),
          src: document.location.hash.split("#/")[1],
          onEnd: handleClose,
        })}
      </div>

      <div className="controls" onClick={handlePlay}>
        <img
          src="/icons/close.svg"
          className="controls_close"
          onClick={handleClose}
          alt={"Close"}
        />
        <img src="/icons/pause.svg" className="controls_play" alt={"Play"} />
      </div>
    </div>
  );
};

const Mobile = () => {
  const info = useMemo(() => {
    return [
      [
        { src: "/icons/info/info1.svg", label: "Explainer Videos" },
        { src: "/icons/info/info5.svg", label: "E-Learning" },
        { src: "/icons/info/info3.svg", label: "Product Demos" },
        { src: "/icons/info/info7.svg", label: "Digital marketing" },
      ],

      [
        { src: "/icons/info/info2.svg", label: "Corporate Communication" },
        { src: "/icons/info/info6.svg", label: "Personalized Sales Outreach" },

        { src: "/icons/info/info4.svg", label: "How-tos & Tutorials" },
        { src: "/icons/info/info8.svg", label: "Training Videos (L&D)" },
      ],
    ];
  }, []);
  const handleArea = () => {
    let vid = document.querySelector(".vid");
    // let videoWrapper = document.querySelector(".videoWrapper");
    // let LandingPage_content = document.querySelector(".LandingPage_content");
    let bigVid = document.querySelector(".bigVid");
    let ClickableArea = document.querySelector(".ClickableArea");
    let playButton = document.querySelector(".playButton");
    // let controls = document.querySelector(".controls");

    vid.style.display = "none";
    playButton.style.display = "none";
    ClickableArea.style.display = "none";
    bigVid.style.display = "block";
    bigVid.controls = true;
    document.bigVid = bigVid;
    bigVid.play();
    bigVid.requestFullscreen();
    // alert("hey");
  };
  return (
    <div className="Mobile">
      <div className="LandingPage">
        <div className="LandingPage_bigContent">
          {/* {ele.logo()} */}
          <div className="LandingPage_content">
            {ele.heading()}
            {ele.subheading()}
            {ele.video({ onClickArea: handleArea })}
            {ele.input()}
          </div>
          {ele.info(info)}
        </div>
      </div>
      <div className="controls">
        <img
          src="/icons/close.svg"
          className="controls_close"
          // onClick={handleClose}
          alt={"Close"}
        />
        <img src="/icons/play.svg" className="controls_play" alt="Play" />
      </div>
    </div>
  );
};


const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { loadUser })(LandingPage);
