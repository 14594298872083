import React from 'react'
import Loading from "../../extras/gifs/loading.gif"
export default function LoadingComp(props) {
    return (
        <div className="loading-sm-component" style={props.style?props.style:{}}>
            <div className="loading-container">
						<img src={Loading} alt=""/>
						<label htmlFor="" className="loading-name">Loading...</label>
						</div>
        </div>
    )
}
