import { useGoogleLogin } from "react-google-login";
import { useGoogleLogout } from "react-google-login";
import { connect } from "react-redux";
import { ReactComponent as Google } from "../../../extras/misc/logo-google.svg";
import { login } from "../../../actions/auth";

function GoogleLoginHooks(props) {
  //const dispatch = useSelector(state => state)

  const onSuccess = (res) => {
    console.log("lala");
    props.login(null, res.tokenId, "social");
  };
  const onFailure = (res) => {
    console.log("Error", res);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId:
      "640904289231-3fkav684kc3562q2dsgl60fmt5u2ovl8.apps.googleusercontent.com",
    isSignedIn: false,
    accessType: "offline",
  });

  const funtry = () => {
    console.log("ss");
    signIn();
  };

  return (
    <button
      className="google-login"
      onClick={(e) => {
        console.log("lalallkk");
        funtry();
      }}
    >
      <Google style={{ margin: "16px" }} /> Continue with Google
    </button>
  );
}

export function GoogleLogoutHooks(props) {
  const onLogoutSuccess = (res) => {
    props.logout();
  };
  const onFailure = (res) => {
    props.logout();
  };

  const { signOut } = useGoogleLogout({
    onFailure,
    clientId:
      "640904289231-3fkav684kc3562q2dsgl60fmt5u2ovl8.apps.googleusercontent.com",
    onLogoutSuccess,
  });

  return (
    <button
      onClick={signOut}
      className="nav-link btn btn-info btn-sm text-light"
    >
      Logout
    </button>
  );
}

export default connect(null, { login })(GoogleLoginHooks);
