import React from "react";
import "./_CSS.scss";
export default function Button({
  value = "Button",
  id="",
  onClick = () => {},
  isActive = true,
  className = "",
}) {
  return (
    <button
      className={`Button ${isActive ? "" : "Button_active"} ${className}`}
      onClick={onClick}
      id={id}
    >
      {value}
    </button>
  );
}
