import { combineReducers } from "redux";
import auth from "./auth"
import editor from './editor';
import salesPage from "./salesPage"
import Loading from "./loading";
import { media } from "./media";

export default combineReducers({
    auth: auth,
    editor: editor,
    salesPage:salesPage,
    loading:Loading,
    media:media
})