import {
    UNDO_STATE,REDO_STATE,SAVE_CURRENT_STATE,SAVE_FIRST_STATE
} from "../actions/types";

const initalState = {
    past:[],
    present:null,
    future:[],
    first:{},
    fromApp:false
};


function editor(state = initalState, action) {
  const { past, present, future } = state
    switch (action.type) {
        case SAVE_CURRENT_STATE:
      
  var temp = action.payload
  var obj = {
      ...temp
        }

      
      console.log(obj);
        if(Object.keys(temp).length !== 0)
        {
          return {
            ...state,
          past:[...past,present],
          present:{...obj},
          future:[],
          futureLength:0,
          pastLength:past.length
        }
      

       }
       else
       return {...state}
      
         
        case REDO_STATE:
          
            var current = present
          
            past.push(current)
            current = future[future.length-1];
          
            if(future.length>0)
            future.pop()

            return {
                past:past,
                present:current,
                future:future,
                futureLength:future.length,
                pastLength:past.length
              };
       
            case UNDO_STATE:
              var newPresent = past[past.length-1]
            
              var newPast = past
              newPast.pop()
            
              var newFuture = future
              newFuture.push(present)

              return{
                ...state,
                past:newPast,
                present:newPresent,
                future:newFuture,
              }
            case SAVE_FIRST_STATE:
          
              return{
                ...state,
              first:action.payload
              }
        
          case "FROM_APP":
            return {
              ...state,
              fromApp:action.payload
            }

        default:
      
        return state;

      }
}


export default editor;