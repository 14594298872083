import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAIL,
    FORGET_PASSWORD_SUCCESS,
    SIGNUP_VERIFY_FAIL,
    SIGNUP_VERIFY_SUCCESS,
    RESET_STATE
} from "../actions/types";

import {ClientUrl } from "../utils";

const initalState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    isLoading: true,
    user: null,
    payload: null
};

function auth(state = initalState, action) {
// console.log(state);
    switch (action.type) {
        case USER_LOADING:
          return {
            ...state,
            isLoading: true,
          };
        case USER_LOADED:
          return {
            ...state,
            isAuthenticated: true,
            isLoading: false,
            user: action.payload,
          };
        case LOGIN_SUCCESS:
        
          localStorage.setItem('token', action.payload.token);

          if(action.payload.isError){
            return {
              ...state,
              ...action,
              isAuthenticated: false,
              isLoading: false,
              user: null,
              token: null,
            };
          }
          else{
            window.open(`${ClientUrl}/?token=${action.payload.token}`)
            return {
              ...state,
              ...action,
              isAuthenticated: true,
              isLoading: false,
              user: action.payload.user,
              token: action.payload.token,
            };
          }
        

        case FORGET_PASSWORD_SUCCESS:
        case RESET_PASSWORD_SUCCESS:
        case SIGNUP_VERIFY_SUCCESS:
        case REGISTER_SUCCESS:
          
          localStorage.removeItem('token');
          return {
            ...state,
            ...action,
            isAuthenticated: false,
            isLoading: false,
          };

        case REGISTER_FAIL:
        case SIGNUP_VERIFY_FAIL:
        case FORGET_PASSWORD_FAIL:
        case RESET_PASSWORD_FAIL:
        case LOGIN_FAIL:
          localStorage.removeItem('token');
          return {
            ...state,
            ...action,
            token: null,
            user: null,
            isAuthenticated: false,
            isLoading: false,
          };
          case RESET_STATE:
            return {
              ...state,
              ...action,
              
              isAuthenticated: false,
              isLoading: false,
            };

        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
          return {
            ...state,
            token: null,
            user: null,
            isAuthenticated: false,
            isLoading: false,
          };
        default:
          return state;
      }
}


export default auth;