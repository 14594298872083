import {
    COPY_SALES_PAGE,GET_ERRORS
} from "../actions/types";

const initalState = {
    selected:{}
};


function salesPage(state = initalState, action) {
    switch (action.type) {
        case COPY_SALES_PAGE: 
        console.log(action.payload);
          return {
            selected:action.payload
          };

        case GET_ERRORS:
            console.log(action.error);
            return {
            error:action.error   
            };
       
           
        default:
          return state;
      }
}


export default salesPage;