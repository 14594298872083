import React,{useEffect} from "react";
import ReactDOM from "react-dom";
import LandingPage from "./components/LandingPage/LandingPage";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from "./components/Header/Header";
import Features from "./components/Features/Features";
import Pricing from "./components/Pricing/Pricing";

import LoginForm from "./components/Accounts/Login";
import SignupForm from "./components/Accounts/SignUp";
import ForgotPassForm from "./components/Accounts/ForgetPassword";

import ResetPassword from "./components/Accounts/resetPassword";
import SignupVerify from "./components/Accounts/signUpVerify";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import Model from "./Model";
import Template from "./components/Template/Template";

import "./components/Template/_Atom.scss"
import "./components/Template/_CSS.scss"
// import Model from "./components/Accounts/Model"

export const Page = ({ title, ...props }) => {
  document.title = title;
  return <Route {...props} />;
};

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <>
        <Header  />
        {/* <Model /> */}

        <Switch>
          <Page title = "AI Generated Human Videos" path={"/"} exact render={() => 
            <LandingPage  />
          } />

          <Page title = "Templates | AI Generated Human Videos" path={"/templates"} render={() => (
            <div className="top90" style={{width:"calc(100% - 80px)", padding:"0 40px"}}>
              <Template />
            </div>
            )
          } />

          <Page title = "Features | AI Generated Human Videos" path={"/features"} render={() => (
            <div className="top90">
              <Features />
            </div>
            )
          
          } />

          <Page title = "Pricing | AI Generated Human Videos" path={"/pricing"} render={() => (
            <div className="top90">
              <Pricing />
            </div>
            )
          
          }/>
    
        
        </Switch>

      </>
    </BrowserRouter>
  </Provider>,

  document.getElementById("root")
);
