import React, {useEffect} from "react"; //check 
import Button from "./Button"; //check

export default function Popup({
  name,
  close,
  children,
  className="",
  centerHeader = null,
  rightHeader = null,
  haveButton = true,
  footer,
}) {
  useEffect(()=>{
    if(name === 'Pro Plan' || name === "Corporate Plan"){
      const _email = sessionStorage.getItem("_email")
        if(_email){
          const _emailElm = document.getElementById("email_for_buyNow");
          if(_emailElm){
            _emailElm.value = _email;
            document.getElementById("button-proceed-email").classList.remove("button-custom-disable")
          }
        }
    }
  },[])

  return (
    <div className={`Popup flex column ${className}`}>
      <div className="Popup_header flex">
        <div className="Popup_header_name">{name}</div>
        {centerHeader}
        <div className="Popup_header_close">
          {rightHeader}
          <img
            className="Popup_header_close_close"
            src="/icons/closePopup.svg"
            alt="close"
            onClick={close}
          />
        </div>
      </div>
      <div className="Popup_content">{children}</div>
      {haveButton && (
        <div className="Popup_footer flex">
          {haveButton && (footer || <Button value="Edit Template" />)}
        </div>
      )}
    </div>
  );
}
