import React, { useEffect } from "react";
import { useState } from "react";
import FAQ from "../faq";
import "./_Pricing.scss";
import "../faq/_Faq.scss"
import Popup from "../Template/Popup";
import Modal from "reactjs-popup"; //check
import Button from "../Template/Button";
import { fetcher, url } from "../../utils";

function vaildateEmail(email){
  return email.toLowerCase().match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

export const D = [
  {
    type: "Standard",
    payment_type: 0,
    about: "For small businesses, entrepreneur, and creators",
    isPopular: false,
    isCustom: true,  // theek krna hei
    creditCard: false,
    price: "XX",
    priceValue: "Coming Soon",
    buttonText: "Contact Us",//"Coming Soon",
    popupDetails: "Please enter your Email.",
    popupSubmitButtonText: "Proceed",
    comingSoon: false,//true,
    timetype: "mo",
    annuallyPrice: `Billed Monthly`,
    benefit: [
      "Unlimited Non-Avatar Videos",
      "15 Avatar-Video Credits/mo",
      "100 Video Personalization Credits/mo",
      "5,000 Thumbnail Personalization Credits/mo",
      "5,000 Salespage Personalization Credits/mo",
      "5,000 Image Personalization Credits/mo",
    ],
  },
  {
    type: "Pro",
    payment_type: 1,
    about: "For agencies and growth stage companies",
    isPopular: true,
    isCustom: true,
    price: "29",
    creditCard: false,
    priceValue: "$29",
    buttonText: "Contact Us",//"Buy Now",
    popupDetails: "Please enter your Email.",
    popupSubmitButtonText: "Proceed",
    // ask
    timetype: "mo",
    annuallyPrice: "Billed Monthly",
    additionInfo: "15-day Money-back Guarantee",
    comingSoon: false,
    benefit: [
      "Unlimited Non-Avatar Videos",
      "45 Avatar-Video Credits/mo",
      "300 Video Personalization Credits/mo",
      "30,000 Thumbnail Personalization/mo",
      "30,000 Salespage Personalization/mo",
      "30,000 Image Personalization/mo"
    ],
  },
  {
    type: "Corporate",
    payment_type: null,
    about: "For scaling with APIs and dedicated support",
    isPopular: false,
    isCustom: true,
    price: 45,
    priceValue: null,
    buttonText: "Contact Us",
    popupDetails: "Please enter your e-mail and we'll get in touch with you.",
    popupSubmitButtonText: "Submit",
    creditCard: false,
    comingSoon: false,
    timetype: "mo",
    annuallyPrice: `Billed Monthly`,
    benefit: [
      "API Integration",
      "Procurement Friendly",
      "Add Your Custom Avatars",
      "Dedicated Account Manager",
      "Strategy and Creative Support",
      "Customized Team Onboarding",
    ],
  },
];
export default function DPricing() {

  const [m, setM] = useState(true);
  const [paymentPopup, setPaymentPopup] = useState(false);

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (params.paymentSuccess === '1') {
      setPaymentPopup(true)
    }
  }, [])


  return (
    <div className="DPricing flex column jcenter">
      <div className="DPricing_heading">Simple plans for everyone</div>
      {/* pre-launch-deal */}
      {/* <div className="DPricing_toggle flex jcenter acenter">
        <DToggle onClick={(i)=>setM(!!i)} />
      <div className="save50p">
      <img src="/icons/priceArrow.svg" className="DPricing_arrow"></img>
        <label htmlFor="">save 50%</label>
      </div>

      </div> */}
      {paymentPopup && <Popup
        name={'Payment Successful!'}
        className={`_buynow _paymentPopup`}
        centerHeader={false}
        haveButton={false}
        close={() => {
          setPaymentPopup(false)
        }}
      >
        Thank you for being an early adopter. We will contact you shortly over e-mail.
        You can always reach out to us at hello@autovid.ai
      </Popup>}

      <div className="DPricing_list flex jcenter acenter">
        {D.map((e, i) => {
          return <PricingCard desktop={true} month={m} d={e} haveBorder={i === 1} />;
        })}
      </div>
      <div className="Faq">
        <FAQ />
      </div>
    </div>
  );
}


export const PricingCard = ({ desktop, d, month = true, haveBorder }) => {
  const handleChange = () => {
    if (document.getElementById("email_for_buyNow")?.value.length > 0) {
      document.getElementById("button-proceed-email").classList.remove("button-custom-disable")
    }
    else if (document.getElementById("email_for_buyNow")?.value.length <= 0) {
      document.getElementById("button-proceed-email").classList.add("button-custom-disable")
    }
    else {
      return null
    }
  }


  const BuyNow = ({ Trigger, _data, desktop }) => <Modal
    closeOnDocumentClick={true}
    trigger={Trigger}
    modal
  >
    {(close) => {

      const _submitEmail = () => {
        const _emailElm = document.getElementById("email_for_buyNow");
        const _email = _emailElm.value;
        if (vaildateEmail(_email)) {
          const _crntElm = document.getElementById("button-proceed-email");
          _crntElm.innerHTML = 'Loading...'
          _crntElm.classList.add("button-custom-disable");

          let _reqData = {email: _email}
          if(0 && _data.type === 'Pro'){
            _reqData.paymentType = _data.payment_type;
          }

          fetcher({
            method: "post",
            data: _reqData,
            url: `${url}/api/accounts/client/?type=autovid_${_data.type.toLowerCase()}plan`,//_data.type === 'Pro' ? `${url}/api/payment/stripe/create-session/`: `${url}/api/accounts/client/?type=autovid_corporateplan`,
            res: (res) => {
              _crntElm.classList.remove("button-custom-disable");
              _crntElm.innerHTML = _data.popupSubmitButtonText
              sessionStorage.removeItem('_email')

              if(0 && _data.type === 'Pro'){
                let { isDealEnded, sessionUrl } = res.data;
                if (isDealEnded) {
                  let x = document.getElementById("isDealEndedEmail")
                  let y = document.getElementById("isDealEnded")
                  x.className = "isDealEndedFalse"
                  y.className = "isDealEndedTrue"
                } else {
                  window.open(sessionUrl, "_blank")
                }
              }else{
                let x = document.getElementById("isDealEndedEmail")
                let y = document.getElementById("isDealEnded")
                x.className = "isDealEndedFalse"
                y.className = "isDealEndedTrue"
              }
            }
          })
        }
        else {
          document.getElementById("email_for_buyNow").classList.add("error-camp-input")
        }
      }

      return (
        <Popup
          name={`${_data.type} Plan`}
          close={close}
          className={`_buynow ${desktop ? "" : "MPricing"}`}

          centerHeader={false}
          haveButton={false}
        >
          <div className="isDealEndedFalse" id="isDealEnded">
            {_data.type === 'Proo' ? (<>Ouch, missed it!<br />Sorry our Pre-Launch deal is sold.<br />Don’t worry, we’ll come back to you with discounted plans.</>) : (<><br />We will contact you soon.</>)}
          </div>
          <div className="isDealEndedTrue" id="isDealEndedEmail">
            <label htmlFor="email_for_buyNow">{_data.popupDetails}</label>
            <input type="email" name="email" onInput={handleChange} id="email_for_buyNow" className="_buynow_emailNe" />
            <div className="Popup_footer flex">
              <Button
                className="button-custom-disable" //subject to change when input is filled
                id="button-proceed-email"
                value={_data.popupSubmitButtonText}
                onClick={_submitEmail}
              />
            </div>
          </div>
        </Popup>
      )
    }}
  </Modal>

  return (
    <div className={`PricingCard ${haveBorder ? "PricingCard_border" : ""}`}>
      {/* Heading */}
      <div className="PricingCard_top flex jbetween acenter">
        <div className="PricingCard_type">{d.type}</div>
        {d.isPopular && (
          <div className="PricingCard_popular_tag">Most Popular</div>
        )}
      </div>
      <div className="PricingCard_about">{d.about}</div>

      {/* Price */}
      {!d.isCustom ? (
        <>
          <div className="PricingCard_price flex ">
            {d.priceValue ? <>  <div className="PricingCard_price_value">{d.priceValue}</div></> :
              <>  <div className="PricingCard_price_value"><> ${(month ? 1 : 2) * d.price || d.price}</></div>
                <div className="PricingCard_price_timetype">/{d.timetype}</div></>}
          </div>
          <div className="PricingCard_annual">
            {d.annuallyPrice ? d.annuallyPrice : "Billed monthly until cancelled"}
          </div>
        </>
      ) : (
        <div className="PricingCard_custom">
          <div className="PricingCard_custom_big"> {d.priceValue || <>Custom Pricing</>}</div>
          <div className="PricingCard_custom_small">
            {d.annuallyPrice || <>Depending on your needs and use case</>}
          </div>
          {
          d.additionInfo ? 
          <div className="PricingCard_custom_small" style={{lineHeight: "1.6" }}>
            {d.additionInfo || <></>}
          </div>: <></>
        }
        </div>
      )}
      {/* Try For Free */}



      {d.comingSoon ? <><div className="PricingCard_try flex jcenter acenter"> Coming Soon</div></> : <BuyNow desktop={desktop} _data={d} Trigger={<div className="PricingCard_try flex jcenter acenter" >{d.buttonText}</div>} ></BuyNow>}

      {!d.isCustom && d.creditCard && (
        <div className="PricingCard_card">No Credit card required</div>
      )}
      <div className="PricingCard_benefit">
        {d.benefit.map((e, i) => {
          return (
            <div className="PricingCard_benefit_point flex" key={i}>
              <img src="/images/icons/tick.svg" alt="tick" />
              <div className="PricingCard_benefit_point_value">{e}</div>
            </div>
          );
        })}
      </div>




    </div>
  );
};

export const DToggle = ({ state = 1, onClick = () => { }, arr = ["Monthly", "Yearly"] }) => {
  const [a, setA] = useState(0);

  return (
    <div className="DToggle flex acenter">
      {arr.map((e, i) => {
        return (
          <div
            className={`DToggle_button flex jcenter acenter ${a === i ? "DToggle_active" : ""
              }`}
            onClick={() => {
              setA(i);
              onClick(i);
            }}
          >
            {e}
          </div>
        );
      })}
    </div>
  );
};
