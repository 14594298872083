/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { register, resetState, registerUserWithOrg } from "../../actions/auth";
import {
  LOGIN_FAIL,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
} from "../../actions/types";
import { ReactComponent as Autogenerate } from "../../extras/aiLogo.svg";
import { ReactComponent as VerifyEmailIcon } from "../../extras/misc/mailverify.svg";
import { ReactComponent as CrossError } from "../../extras/misc/loginerrorcross.svg";
import { ReactComponent as PinkTick } from "../../extras/analytics/tickAnalyticSm.svg";
import { ReactComponent as WhiteTick } from "../../extras/misc/tickWhite.svg";
import { ReactComponent as Google } from "../../extras/misc/logo-google.svg";
// import { url } from "../../config/config";

import axios from "axios";
import GoogleAuth from "./social-auth/GoogleHooks";
import { url } from "../../utils";

// import { objectKeys } from 'react-foundation/utils';

function SignUp(props) {
  const [Details, setDetails] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [Step, setStep] = useState(0);
  const [Errors, setErrors] = useState({});
  const [InsideErrors, setInsideErrors] = useState({
    isError: false,
    error: [],
  });

  console.log(Details);
  console.log(Errors);

  useEffect(() => {
    console.log("yee");
    if (props.state.payload && props.state.payload.isError) {
      var obj;

      Object.keys(props.state.payload.message).map((d) => {
        obj = {
          isError: true,
          ...obj,
          message:
            d === "email"
              ? props.state.payload.message[d].status === 1
                ? "Email already registered."
                : "Something went wrong. Please contact the Autogenerate.ai"
              : "Something Went wrong. Please contact the Autogenerate.ai",
        };
      });

      setErrors(obj);
    } else {
      if (
        props.state.payload &&
        props.state.payload.isError === false &&
        Details.code == null
      ) {
        setErrors({
          isError: false,
        });
        setStep(1);
      }
      if (Details.code?.length > 0) {
        window.location.href = "/login";
      }
    }
  }, [props.state]);

  const handlerSubmit = (e) => {
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var password = /[!@#$%^&*(),.?":{}|<>-_;:|]/g;
    if (
      Details.email.match(mailformat) &&
      Details.password.length >= 8 &&
      Details.password.match(password) &&
      Details.name.length > 0
    ) {
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get("code");

      console.log(myParam);

      if (Details.code && Details.code?.length > 0) {
        props.registerUserWithOrg(
          Details.name,
          Details.code,
          Details.email,
          Details.password
        );
      } else {
        props.register(Details.name, Details.email, Details.password);
      }

      // props.login(Details.email,Details.password)

      // setStep(1)
    } else {
      if (!Details.name.length > 0) {
        document.getElementById("name-input").classList.add("errorInput");
      }
      if (!Details.email.match(mailformat)) {
        document.getElementById("email-input").classList.add("errorInput");
      }

      var arr = [];
      var error = false;

      if (Details.password.length <= 8) {
        document.getElementById("password-input").classList.add("errorInput");
        error = true;
        arr.push({
          status: false,
          message: "8 or more characters",
        });
      } else {
        arr.push({
          status: true,
          message: "8 or more characters",
        });
      }

      if (Details.password.match(password)) {
        document.getElementById("password-input").classList.add("errorInput");
        error = true;
        arr.push({
          status: true,
          message: "At least 1 symbol",
        });
      } else {
        arr.push({
          status: false,
          message: "At least 1 symbol",
        });
      }
    }

    if (arr && arr.length > 0 && error) {
      setInsideErrors({
        isError: true,
        error: arr,
      });
    } else {
      setInsideErrors({
        isError: false,
        error: [],
      });
    }
  };

  const handleChange = (e) => {
    e.target.classList.remove("errorInput");
    setInsideErrors({ isError: false, error: [] });

    setDetails({ ...Details, [e.target.name]: e.target.value });
  };
  useEffect(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("code");

    console.log(myParam);

    if (myParam) {
      var res = await axios.get(
        `${url}/api/accounts/user/organization/codeverify/?code=${myParam}`
      );
      if (res.data.isError) {
        var obj = {
          isError: true,
          ...obj,
          message: "Verification failed",
        };
        setErrors(obj);
      } else {
        setDetails({ ...Details, email: res.data.email, code: myParam });
      }
    }
  }, []);
  const al = () => {
    if (Step === 0) {
      return (
        <div
          key={0}
          className={`login-wrapper ${Step === 0 ? "MoveLeft" : ""}`}
        >
       

          <div className="login-inner-wrapper">
            <div className="login-gp-1">
              <GoogleAuth />
              {/* <button className="google-login"><Google style={{margin:"16px"}} /> Continue with Google</button> */}
            </div>

            <h6 className="word-btw-line login">
              <span>OR</span>
            </h6>

            <div className="login-form">
              {Errors.isError ? (
                <div className="error-login-info">
                  <label htmlFor="" className="error-login-info-heading">
                    Error !
                  </label>
                  <label htmlFor="" className="error-login-info-label">
                    {Errors.message}
                  </label>
                  <CrossError
                    className="pointer"
                    onClick={() => {
                      setErrors({
                        ...Errors,
                        isError: false,
                      });
                    }}
                    style={{ position: "absolute", right: "16px", top: "35%" }}
                  />
                </div>
              ) : null}
              <input
                id="name-input"
                type="text"
                className="login-item"
                placeholder="Name"
                name="name"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <input
                id="email-input"
                type="email"
                className="login-item"
                placeholder="Email"
                name="email"
                defaultValue={Details.email}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <span style={{ width: "100%", position: "relative" }}>
                <input
                  id="password-input"
                  type="password"
                  className="login-item"
                  placeholder="Password"
                  name="password"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {InsideErrors.isError ? (
                  <div className="error-info-tooltip-tool">
                    <label htmlFor="" className="error-info-tooltip-head">
                      Password must have:
                    </label>
                    {InsideErrors.error.map((d) => (
                      <label htmlFor="" className="error-info-item">
                        {" "}
                        {d.status ? <PinkTick /> : <WhiteTick />} &nbsp;{" "}
                        {d.message}
                      </label>
                    ))}
                  </div>
                ) : null}
              </span>
            </div>

            <div className="login-footer">
              <button
                className="login-main-button"
                onClick={(e) => {
                  handlerSubmit(e);
                }}
              >
                Create Account
              </button>

              <label className="login-main-label">
                Already a member?{" "}
                <Link to="/login" className="link-custom">
                  Log in.
                </Link>
              </label>
              {/* <Link to="/signup" className="link-custom forget-login-main" >Forgot Password?</Link> */}
            </div>
          </div>
        </div>
      );
    }
    if (Step === 1) {
      return (
        <div
          key={1}
          className={`login-wrapper ${Step === 1 ? "MoveLeft" : ""}`}
          style={{ maxWidth: "513px" }}
        >
          <Autogenerate style={{ width: "240px" }} />

          <h1 className="login-header">Verify email.</h1>

          <div className="login-inner-wrapper">
            <div className="login-gp-1">
              <label className="login-main-label">
                Verify email id to continue. A verification email has been sent
                to{" "}
              </label>
              <label className="login-verification-label">
                {Details.email}
              </label>

              {/* <GoogleAuth /> */}
              {/* <button className="google-login"><Google style={{margin:"16px"}} /> Continue with Google</button> */}
            </div>

            <div className="login-form">
              <VerifyEmailIcon />
            </div>

            <div className="login-footer">
              <Link
                to={{
                  pathname: "/login",
                  email: Details.email,
                }}
                className="login-main-button link-custom"
                style={{ margin: "50px 0 0 0", width: "200px" }}
                onClick={(e) => {
                  handlerSubmit(e);
                }}
              >
                Login
              </Link>

              {/* <Link to="/signup" className="link-custom forget-login-main" >Forgot Password?</Link> */}
            </div>
          </div>
        </div>
      );
    }
  };

  // console.log(InsideErrors);

  if (props.isAuthenticated) {
    return <Redirect to="/" />;
  } else return <div className="login-container">{al()}</div>;
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  state: state.auth,
});

export default connect(mapStateToProps, {
  register,
  resetState,
  registerUserWithOrg,
})(SignUp);
//export default LoginForm;
