import { isMobile } from "mobile-device-detect";
import React from "react";
import DFeatures from "./DFeatures";
import MFeatures from "./MFeatures";
import "./_Features.scss";

export default function Features() {

  return isMobile ? <MFeatures /> : <DFeatures />;
  // return true ? <MFeatures /> : <DFeatures />; // mobile testing purpose
}
