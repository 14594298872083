import {
    IS_LOADING
} from "../actions/types";

const initalState = {
    isLoading:true
};


function editor(state = initalState, action) {
  // const { isLoading } = state
    switch (action.type) {
        case IS_LOADING:
      
       return {...state,isLoading:action.payload}
      
         
        
        default:
      
        return state;

      }
}


export default editor;