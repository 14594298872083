// import { isMobile } from "mobile-device-detect";
import React,{useState} from "react";
import { useEffect } from "react";
import FAQ from "../faq";
import Popup from "../Template/Popup";
import { D } from "./DPricing";
import { DToggle } from "./DPricing";
import { PricingCard } from "./DPricing";
export default function MPricing() {
  const [m, setM]=useState(true)


  const [paymentPopup, setPaymentPopup]=useState(false);
  useEffect(()=>{
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if(params.paymentSuccess==='1'){
      setPaymentPopup(true)
    }
  },[])

useEffect(()=>{
let a = document.querySelector('.DPricing_list');
if(a){
  a.scrollLeft += a.scrollWidth*0.3
  // a.scroll(100,100)
  console.log(  a.scrollLeft);
}
},[])

const Arrow = ({where}) =>{
  if(where){
return (<svg xmlns="http://www.w3.org/2000/svg" width="14.357" height="7.059" viewBox="0 0 14.357 7.059">
<path id="Icon_awesome-long-arrow-alt-left" data-name="Icon awesome-long-arrow-alt-left" d="M10.061,15.07H.385A.385.385,0,0,1,0,14.685V12.89a.385.385,0,0,1,.385-.385h9.677V11.029a.769.769,0,0,1,1.313-.544l2.758,2.758a.769.769,0,0,1,0,1.088L11.374,17.09a.769.769,0,0,1-1.313-.544Z" transform="translate(0 -10.258)" fill="#a9a9a9"/>
</svg>
)
  }
  else{
    return (<svg xmlns="http://www.w3.org/2000/svg" width="14.357" height="7.057" viewBox="0 0 14.357 7.057">
    <path id="Icon_awesome-long-arrow-alt-left" data-name="Icon awesome-long-arrow-alt-left" d="M4.3,15.069h9.677a.385.385,0,0,0,.385-.385V12.889a.385.385,0,0,0-.385-.385H4.3V11.029a.769.769,0,0,0-1.313-.544L.225,13.243a.769.769,0,0,0,0,1.088l2.758,2.758A.769.769,0,0,0,4.3,16.545Z" transform="translate(0 -10.258)" fill="#a9a9a9"/>
  </svg>
  )
  }
}
  return (
    <div className="DPricing MPricing flex column jcenter">
      <div className="DPricing_heading">Simple plans for everyone</div>
      {/* <div className="DPricing_toggle flex jcenter acenter">
        <DToggle onClick={(i)=>setM(!!i)}/>
        <div className="save50p">
      <img src="/icons/priceArrow.svg" className="DPricing_arrow"></img>
        <label htmlFor="">save 50%</label>
      </div>
      </div> */}
      <div className="PricingCard_annual acenter SwipePlans">
      <Arrow where={0} />  Swipe for other plans   <Arrow where={1} />
      </div>
      {paymentPopup && <Popup
        name={'Payment Successful!'}
        className={`_buynow MPricing MPaymentSuccess`}
        centerHeader={false}
        haveButton={false}
        close={()=>{
          setPaymentPopup(false)
        }}
      >
        Thank you for being an early adopter. We will contact you shortly over e-mail. 
        You can always reach out to us at hello@autovid.ai
      </Popup>}
      <div className="DPricing_list flex  acenter">
        {D.map((e, i) => {
          return <PricingCard desktop={false} month={m} d={e} haveBorder={i === 1} />;
        })}
      </div>

      <div className="Faq">
  <FAQ />
</div>


    </div>
  );
}
