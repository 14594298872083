/* eslint-disable */
import React, { useState, useEffect } from "react";
import { login } from "../../actions/auth";
import { connect } from "react-redux";
import { Link, Redirect, useParams, useRouteMatch } from "react-router-dom";
import { ReactComponent as Autogenerate } from "../../extras/aiLogo.svg";
import { ReactComponent as VerifyEmailIcon } from "../../extras/misc/mailverify.svg";
import { ReactComponent as Google } from "../../extras/misc/logo-google.svg";
import GoogleAuth from "./social-auth/GoogleHooks";
import axios from "axios";
// import { url } from "../../config/config";

import { ReactComponent as CrossError } from "../../extras/misc/loginerrorcross.svg";
import LoadingComp from "../common/loading";
import { url } from "../../utils";
function Login(props) {
  let { id } = useParams();
  const [Details, setDetails] = useState({
    email: props.location && props.location.email ? props.location.email : "",
    code: id,
  });
  const [Step, setStep] = useState(-1);
  const [Errors, setErrors] = useState({ isError: false });
  const [PasswordChange, setPasswordChange] = useState({ isChanged: false });
  const [Redirectt, setRedirectt] = useState(false);
  console.log(props);

  useEffect(async () => {
    const res = await axios.get(
      `${url}/api/accounts/signup/verify/?code=${id}`
    );

    if (res.data.isError) {
      setErrors({
        isError: true,
        message: res.data.message,
      });
    } else {
      setStep(0);
      setDetails({ email: res.data.email });
      setRedirectt(true);
    }
  }, []);

  useEffect(() => {
    const messages = {
      email: {
        0: "Email is Blocked.",
        1: "Email is not varified.",
        2: "Email is not linked with any organisation.",
      },
      password: {
        0: "Invalid password.",
      },
    };

    if (props.state.auth.payload && props.state.auth.payload.isError) {
      var obj;

      Object.keys(props.state.auth.payload.message).map((d) => {
        obj = {
          isError: true,
          ...obj,
          message: messages[d][props.state.auth.payload.message[d].status],
        };
      });

      setErrors(obj);
    } else {
      setErrors({
        isError: false,
      });
      if (
        props.state.auth.payload &&
        props.state.auth.payload.isError === false &&
        props.isAuthenticated
      ) {
        window.open("/");
      }
    }

    console.log(props.state);
  }, [props.state]);

  console.log(Errors);

  useEffect(() => {
    if (props.location.passwordChange) {
      setPasswordChange({
        isChanged: true,
        message: "Login to continue.",
      });
    }
  }, []);

  const handlerSubmit = (e) => {
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (Details.email.match(mailformat) && Details.password.length > 3) {
      console.log("ll");

      props.login(Details.email, Details.password);
    } else {
      if (!Details.email.match(mailformat)) {
        console.log("ll");

        document.getElementById("email-input").classList.add("errorInput");
      }

      if (Details.password.length < 3) {
        console.log("ll");

        document.getElementById("password-input").classList.add("errorInput");
      }
    }
  };

  const handleChange = (e) => {
    e.target.classList.remove("errorInput");
    setErrors({ isError: false });
    setDetails({ ...Details, [e.target.name]: e.target.value });
  };

  const al = () => {
    if (Step === -1) {
      return <LoadingComp style={{ width: "100vw", height: "100vh" }} />;
    }
    if (Step === 0) {
      return (
        <div
          key={1}
          className={`login-wrapper ${Step === 1 ? "MoveLeft" : ""}`}
          style={{ maxWidth: "513px" }}
        >
          <Autogenerate style={{ width: "240px" }} />

          <h1 className="login-header">Verify email Failed.</h1>

          <div className="login-inner-wrapper">
            <div className="login-gp-1">
              <label className="login-main-label">
                Verification of email failed{" "}
              </label>
              {/* <label className="login-verification-label">{Details.email}</label> */}

              {/* <GoogleAuth /> */}
              {/* <button className="google-login"><Google style={{margin:"16px"}} /> Continue with Google</button> */}
            </div>

            <div className="login-form">
              <VerifyEmailIcon />
            </div>

            <div className="login-footer">
              <Link
                to={{
                  pathname: "/login",
                  // email:Details.email
                }}
                className="login-main-button link-custom"
                style={{ margin: "50px 0 0 0", width: "200px" }}
                onClick={(e) => {
                  handlerSubmit(e);
                }}
              >
                Login
              </Link>

              {/* <Link to="/signup" className="link-custom forget-login-main" >Forgot Password?</Link> */}
            </div>
          </div>
        </div>
      );
    }
  };

  console.log(props);

  if (Redirectt) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          email: Details.email,
          emailVerified: true,
        }}
      />
    );
  } else return <div className="login-container">{al()}</div>;
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  state: state,
});
export default connect(mapStateToProps, { login })(Login);
