/* eslint-disable */
// import { Redirect } from 'react-router-dom';
// import React from "react";
// import classNames from 'classnames';
// import { connect } from 'react-redux';
// import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn } from 'mdbreact';
// import { FORGET_PASSWORD_FAIL,FORGET_PASSWORD_SUCCESS } from "../../actions/types";
// import { forgetPassword } from '../../actions/auth';

// class ResetPasswordForm extends React.Component {
//   is_valid = { "email": true };
//   state = { email: "", mailSent: false, errors: { "email": '' } };
//   btnClick = false;

//   componentDidUpdate() {
//     if (this.btnClick && this.props.state.auth.type === FORGET_PASSWORD_FAIL) {
//       let nerror = {  "email": '' };
//       Object.entries(this.props.state.auth.payload.response).forEach(([key, value]) => {
//         nerror[key] = value.join(' && ');
//         this.is_valid[key] = false;
//       });
//       this.setState((state, props) => ({
//         errors: { ...state.errors, ...nerror }
//       }));
//       this.btnClick = false;
//     } else if (this.btnClick && this.props.state.auth.type === FORGET_PASSWORD_SUCCESS) {
//       this.setState((state,props) => ({
//         mailSent: true
//       }));
//       this.btnClick = false;
//     }
//   }

//   submitHandler = event => {
//     this.is_valid = { "email": true, "password": true };
//     event.preventDefault();
//     this.props.forgetPassword(this.state.email);
//     this.btnClick = true;
//   };

//   changeHandler = event => {
//     this.setState({ [event.target.name]: event.target.value });

//   };

//   render(){
//     if (this.props.isAuthenticated) {
//       return <Redirect to="/" />;
//     }

//     if(this.state.mailSent){
//       return (
//         <div style={{display: 'flex', justifyContent: 'center',alignContent: 'center', alignItems: 'center',marginTop: "200px"}}>
//           <MDBContainer>
//             <MDBRow center>
//                 <h6>You will receive an email with instructions about how to reset your password in a few minutes.</h6>
//             </MDBRow>
//         </MDBContainer>
//         </div>
//       );
//     } else{
//       const emailGroupClass = classNames('form-group',
//         { 'has-error': !this.is_valid.email }
//       );
//       return (
//         <div className="container" style={{ width: '500px', marginTop: '50px' }}>
//           <form className="form-signin" onSubmit={this.submitHandler} >
//             <MDBContainer>
//               <MDBRow center>
//                 <MDBCol>
//                   <MDBCard >
//                     <MDBCardBody className="mx-4">
//                       <div className="text-center">
//                         <h3 className="dark-grey-text mb-5">
//                           <strong>Forgot Password</strong>
//                         </h3>
//                       </div>
//                       <div className="text-center">
//                         <p className="dark-grey-text mb-6">
//                           Provide your registered email ID to receive password reset instructions
//                     </p>
//                       </div>

//                       <div className={emailGroupClass}>
//                         <input
//                           type="email"
//                           name="email"
//                           className="form-control"
//                           placeholder="Your Email"
//                           value={this.state.email}
//                           onChange={this.changeHandler}
//                           autoFocus
//                         />
//                         <span className="help-block">{this.state.errors.email}</span>
//                       </div>

//                       <div className="text-center mb-3">
//                         <MDBBtn
//                           type="submit"
//                           gradient="blue"
//                           rounded
//                           className="btn-block z-depth-1a"
//                         >
//                           Send Email
//                     </MDBBtn>
//                       </div>

//                     </MDBCardBody>

//                   </MDBCard>
//                 </MDBCol>
//               </MDBRow>
//             </MDBContainer>
//           </form>
//         </div>

//       );
//     }

//   }

// }

import { ReactComponent as CrossError } from "../../extras/misc/loginerrorcross.svg";

import React, { useState } from "react";
import { forgetPassword } from "../../actions/auth";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { ReactComponent as Autogenerate } from "../../extras/aiLogo.svg";

import axios from "axios";
// import {url} from "../../config/config"

import { ReactComponent as VerifyEmailIcon } from "../../extras/misc/mailverify.svg";
import { url } from "../../utils";

function ResetPasswordForm(props) {
  const [Details, setDetails] = useState({
    email: "",
  });
  const [Step, setStep] = useState(0);
  const [Errors, setErrors] = useState({ isError: false });

  console.log(props);

  const forgetpassword = async (e) => {
    const res = await axios.post(`${url}/api/accounts/password/reset/`, {
      email: e,
    });
    const messages = {
      email: {
        0: "Email not registered.",
      },
    };
    console.log(res);
    if (res.data.isError) {
      var obj;

      Object.keys(res.data.message).map((d) => {
        obj = {
          isError: true,
          ...obj,
          message: messages[d][res.data.message[d].status],
        };
      });

      setErrors(obj);
    } else {
      setStep(1);
      setErrors({
        isError: false,
      });
    }
  };

  console.log(Errors);

  const handlerSubmit = (e) => {
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (Details.email.match(mailformat)) {
      forgetpassword(Details.email);
    } else {
      if (!Details.email.match(mailformat)) {
        console.log("ll");

        document.getElementById("email-input").classList.add("errorInput");
      }
    }
  };

  const handleChange = (e) => {
    e.target.classList.remove("errorInput");

    setDetails({ ...Details, [e.target.name]: e.target.value });
  };

  const al = () => {
    if (Step === 0) {
      return (
        <div className="login-wrapper">
     

          <div className="login-inner-wrapper">
            <div className="login-form">
              {Errors.isError ? (
                <div className="error-login-info">
                  <label htmlFor="" className="error-login-info-heading">
                    Error !
                  </label>
                  <label htmlFor="" className="error-login-info-label">
                    {Errors.message}
                  </label>
                  <CrossError
                    className="pointer"
                    onClick={() => {
                      setErrors({
                        ...Errors,
                        isError: false,
                      });
                    }}
                    style={{ position: "absolute", right: "16px", top: "35%" }}
                  />
                </div>
              ) : null}
              <input
                id="email-input"
                type="email"
                className="login-item"
                placeholder="Email"
                name="email"
                defaultValue={
                  props.location && props.location.email
                    ? props.location.email
                    : ""
                }
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              {/* <input id="password-input" type="password" className="login-item" placeholder="Password" name="password" onChange={(e)=>{handleChange(e)}}  /> */}
            </div>

            <div className="login-footer">
              <button
                className="login-main-button"
                onClick={(e) => {
                  handlerSubmit(e);
                }}
              >
                Reset Password
              </button>

              <label className="login-main-label">
                Go back to{" "}
                <Link to="/login" className="link-custom">
                  Log in
                </Link>
              </label>
            </div>
          </div>
        </div>
      );
    }
    if (Step === 1) {
      return (
        <div
          key={1}
          className={`login-wrapper ${Step === 1 ? "MoveLeft" : ""}`}
          style={{ maxWidth: "513px" }}
        >
          <Autogenerate style={{ width: "240px" }} />

          <h1 className="login-header">Email sent.</h1>

          <div className="login-inner-wrapper">
            <div className="login-gp-1">
              <label className="login-main-label">
                An email with the link to change password has been sent to{" "}
              </label>
              <label className="login-verification-label">
                {Details.email}
              </label>

              {/* <GoogleAuth /> */}
              {/* <button className="google-login"><Google style={{margin:"16px"}} /> Continue with Google</button> */}
            </div>

            <div className="login-form">
              <VerifyEmailIcon />
            </div>

            <div className="login-footer">
              <Link
                to={{
                  pathname: "/login",
                  email: Details.email,
                }}
                className="login-main-button link-custom"
                style={{ margin: "50px 0 0 0", width: "200px" }}
                onClick={(e) => {
                  handlerSubmit(e);
                }}
              >
                Login
              </Link>

              {/* <Link to="/signup" className="link-custom forget-login-main" >Forgot Password?</Link> */}
            </div>
          </div>
        </div>
      );
    }
  };



  if (props.isAuthenticated) {
    return <Redirect to="/" />;
  } else return <div className="login-container">{al()}</div>;
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  state: state,
});

export default connect(mapStateToProps, { forgetPassword })(ResetPasswordForm);
